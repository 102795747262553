/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Elements
import ParseContent from 'components/shared/ParseContent'
import TitleDefault from 'components/elements/TitleDefault'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'

const PageTemplate = ({
  data: {
    page: { path, title, content, yoast_meta: yoast },
  },
}) => {
  return (
    <Layout>
      <SEO yoast={yoast} path={path} />

      <div className="container py-5">
        <TitleDefault>{title}</TitleDefault>
        <ParseContent content={content} />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path

      yoast_meta {
        name
        content
        property
      }
    }
  }
`

export default PageTemplate
